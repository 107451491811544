.auth-popup-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 300px;
}

.email-auth {
    display: flex;
    flex-direction: column;
    gap: 1em;
    /* margin: 1em 0; */
    padding: 1em;
    border: 1px solid var(--grey);
    border-radius: var(--standard-radius);
}

.email-auth > h3 {
    margin: 0;
}
