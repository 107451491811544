.img-preview {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #ccc;
  text-align: center;
}
.img-preview::before {
    display: block;
    margin-top: 34%;    /* magin number moving alt text to the center of the image */
}