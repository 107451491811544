.tab-group {
    display: flex;
    border-bottom: 1px solid var(--grey);
    justify-content: left;
    overflow: auto;
    flex-shrink: 0;
}

.tab {
    display: flex;
    align-items: center;
    gap: 0.1em;
    margin-top: 0.5em;
    padding-inline: 1em;
    line-height: 2.5em;
    border-radius: 4px 4px 0px 0px;
}
.tab:hover {
    background-color: var(--dark-2);
    cursor: pointer;
}

.tab .label {
    white-space: nowrap;
    text-overflow: ellipsis;
}
/* SOLUTION */
/* The pseudo element has the same content and hover style, so it pre-sets the width of the element and visibility: hidden hides the pseudo element from actual view. */
/* https://stackoverflow.com/questions/556153/inline-elements-shifting-when-made-bold-on-hover/20249560#20249560 */
/* .tab::before {
    display: block;
    content: attr(label);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
} */

.tab.active {
    color: var(--primary);
    font-weight: 800;
    border-bottom: 2px solid var(--primary);
}

.tab.disabled {
    color: var(--grey);
}

.tab.disabled:hover {    
    cursor: not-allowed;
}

.tab-outlet {
    display: flex;
    flex-direction: column;
    margin: 1em 0;
    gap: 1em;
    /* align-items: center; */
}