.selection-item .input {    
    grid-area: input;
    display: grid;
    justify-content: space-between;
    align-items: center;
    place-self: center;
}


.input-number {
    display: flex;
    align-items: center;
    width: fit-content !important;
}
.input-number > input {
    flex-grow: 0;
    width: 6em;
}