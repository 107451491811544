.place {
    display: inline-block;
    font-weight: 600;
    /* background-color: var(--dark-2); */
    color: var(--light-2);
    border-radius: calc(var(--standard-radius) + 4px);
    font-style: italic;
    padding: auto;
    width: 1.8em;
    height: 1.8em;
    /* line-height: 1.8em; same as height in order to vertically align text */
    overflow: clip;
}

.place.first {
    color: rgb(189, 138, 11) !important;
    background-color: gold !important;
    border: 2px solid rgb(189, 138, 11) !important;
}

.place.second {
    color: gray !important;
    background-color: silver !important;
    border: 2px solid gray !important;
}

.place.third {
    color: rgb(165, 74, 0) !important;
    background-color: rgb(204, 128, 92) !important;
    border: 2px solid rgb(165, 74, 0) !important;
}
