.schedule {
    padding-inline: 0;
    padding-bottom: 0;
}


.carousel {
    flex-grow: 1;
    display: flex;
    /* flex-direction: column; */
    gap: 0.5em;
    margin-top: 1em;
    min-height: 70dvh;
    align-items: flex-start;
    overflow: auto;
    scroll-snap-type: x mandatory;
}
.carousel-item {
    display: grid;
    justify-items: center;
    /* scroll-padding- */
    scroll-snap-align: center;
}
.carousel-item:first-child {
    margin-left: calc(300px + 2 * 0.5em);
}
.carousel-item:last-child {
    margin-right: calc(300px + 2 * 0.5em);
}



.event-poster {
    display: flex;
    flex-direction: column;
    box-shadow: 0 1px 3px #151515, 0 1px 2px #151515;
    border: 1px solid var(--dark-1);
    border-radius: var(--standard-radius);
    margin: 1em 0;
    
    /* height: 400px; */
    width: 300px;
    aspect-ratio: 2 / 3;
    
    color: white;
    text-shadow: 0px 2px 6px rgb(0 0 0 / 1);
    overflow: hidden;
}

.event-poster:hover {
    cursor: pointer;
}

.event-poster-hero {
    flex-grow: 1;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background-position: center center; 
    /* padding: 1em; */

}

.event-poster h2 {
    font-weight: bold;
    margin: 0;
    max-width: 100%;
    padding-inline: 0.2em;
    padding-bottom: 0.2em;
}

.event-poster > div {
    text-align: center;
}

.event-poster-info {
    padding: 1em;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.flag {
    height: 1.5em;
    aspect-ratio: 3/2;
    border-radius: 4px;
    object-fit: cover;
    box-shadow: 0px 3px 4px rgb(0 0 0 / 0.4);
}
.flag.small {
    height: 1em;
}

.circuit-map {
    height: 3em;
    aspect-ratio: 1;
    filter: invert() drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
    display: block;
    object-fit: contain;
}
.circuit-map.small {
    height: 2em;
}



.sprint-label {
    font-size: .8rem;
    padding: 0em 1em;
    color: rgb(112, 101, 1);
    background: rgb(248,255,157);
    background: 
        linear-gradient(100deg, 
            rgba(248,255,157,0) 5%, 
            rgba(248,255,157,1) 15%, 
            rgba(248,255,157,1) 85%, 
            rgba(248,255,157,0) 95%) padding-box,
        linear-gradient(100deg, 
            rgba(112, 101, 1, 0) 5%, 
            rgba(112, 101, 1,1) 15%, 
            rgba(112, 101, 1,1) 85%, 
            rgba(112, 101, 1,0) 95%) border-box;
    border: 2px solid transparent;
}

.event-action {
    display: flex;
    flex-direction: row;
    align-self: center;
}


.end-of-season {
    /* position: absolute; */
    /* bottom: 0.5em;
    left: 0; */
    flex-grow: 1;
    height: 15vh;
    width: calc(100dvw - 0.5em);

    background-image: 
        radial-gradient(ellipse at bottom, rgba(19, 19, 19, 0.5) 30%, var(--dark-4) 60%), 
        url('https://wus1saformulafantasy.blob.core.windows.net/formulafantasy-artifacts/EndOfSeason.jfif');
    background-size: cover;
    background-position: 0% 65%; 

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    font-weight: 800;
    text-shadow: 1px 1px 4px var(--dark-2);
}

.schedule-empty-state {
    height: 300px;
    width: min(100%, 400px);
    background-image: 
     
        url('https://wus1saformulafantasy.blob.core.windows.net/formulafantasy-artifacts/empty_track.jpg');
    background-size: cover;
    background-position: 0% 65%; 
    border-radius: var(--standard-radius);

    display: grid;
    justify-content: center;
    margin: 0 auto;
}

.schedule-empty-state > h1 {
    mix-blend-mode: difference;
    padding: 0 auto;
    text-align: center;
}