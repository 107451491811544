.driver-container {
    gap: 0;
}

.driver-container .main {
    display: grid;

    grid-template-areas: 'details';
    grid-template-columns: auto;
    gap: 0;
    
    padding: 0.5em;
}

/* .driver[variant|="input"] .main {
    grid-template-areas: 'input details';
    grid-template-columns: 1em auto;
    column-gap: 0.5em;
} */


.driver-container .number {
    grid-area: input;
    justify-self: center;
}
/* .driver .input {
    grid-area: input;
    display: grid;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    place-self: center;
} */

.driver-container .driver-points {
    grid-area: details;
    justify-self: end;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 6px;   /* Magin number. Adjust as needed */
}

.driver-container .driver-answer {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    /* padding: 0 0.5em; */
}

.driver-container .driver-answer h3 {
    /* font-size: 1.5em; */
    line-height: 1em;
}

.driver-container .driver-details {
    grid-area: details;
    /* overflow: hidden; */
    /* padding-bottom: 0.5em; */
    align-items: center;
}

.driver-container .projected-stats {
    font-style: italic;
    font-size: 0.8em;
}

.constructor-logo-inline {
    max-height: 1em;
    max-width: 1.5em;
}









.driver-container .stats-accordian {
    grid-area: stat-accordian;
    transition: all 0.3s ease; /* Smooth transition */
    max-height: 0; /* Initially hidden */
    overflow: hidden;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    padding-block: 0;
}
.driver-container .stats-accordian.open {
  max-height: 600px; /* Adjust as needed */
  padding-block: 1em;
  border-top: 1px solid var(--dark-2);
}
@media (max-width: 680px) {
  .driver-container .stats-accordian {
    grid-template-columns: 1fr;
  }
}