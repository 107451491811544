.results-pending-banner {
    margin: 1em;
    flex-grow: 1;
    border: 1px solid goldenrod;
    border-radius: var(--standard-radius);
    background-color: gold;
    color: rgb(90, 66, 6);
    backdrop-filter: brightness(150%);
    text-align: center;
}

.avatar-container {
    display: flex;
}
.avatar-container .avatar {
    flex-grow: 1;
    flex-shrink: 1;
}




.highlighted-user {
    background-color: rgb(77, 68, 41);
}

/* ------------------------------- */


