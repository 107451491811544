/* .countdown {
    margin: 0 auto;

    background-image: 
        linear-gradient(to bottom, 
            rgba(0, 0, 0, 0.4) 70%, 
            rgba(14, 14, 14, 0.9) 80%), 
        url('https://wieck-honda-production.s3.amazonaws.com/photos/0c311ee1f3497eb2a8fdf1acf3dbb9f7c8c41757/preview-928x522.jpg');
    background-size: cover;
    background-position: 15% 25%;    
    
    border-radius: var(--standard-radius);
    text-align: center;
    display: grid;
    gap: 1em;
    padding: 1.5em;
    padding-bottom: 0.5em;
    justify-items: center;
} */


/* @media (min-width: 480px) {
    .countdown {
        padding: 2em 6em;
    }
} */

.count-box-container {
    display: flex;
    gap: 1em;
}

.count-box-container .count-box {
    background: rgba(41, 41, 43, 0.8);
    border-radius: var(--standard-radius);
    /* padding: 1em; */
    width: 100px;
    height: 100px;
    display: grid;
    /* gap: 0.5em; */
    align-items: center;
}

.count-box-container .count-box .number {
    line-height: 1em;
    font-size: 2em;
    font-weight: 800;
    align-self: end;
}