.logo {
  height: 36px;
}

.header {
  --header-v-margin: 1em;
  --header-h-margin: 1em;
  box-sizing: border-box;
  position: fixed;
  top: var(--header-v-margin);
  left: var(--header-h-margin);
  height: 64px;
  width: calc(100dvw - 2 * var(--header-h-margin));
  z-index: 100;

  border-radius: var(--standard-radius);
  background-color: rgba(28, 28, 28, 0.5);
  backdrop-filter: blur(10px);
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1em;
}


.menu-cluster {
  display: flex;
  align-items: center;
  gap: 1em;
}
.menu-btn {
  display: flex;
  align-items: center;
}
.menu-btn:hover {
  cursor: pointer;
}

.header .header-chip-avatar {
  display: none;
}

@media (max-width: 680px) {
  .logo {
    height: 32px;
  }
  .header {
    --header-h-margin: var(--header-v-margin);
  }
  .menu-cluster {
    gap: 0;
  }
  .header .header-chip {
    display: none;
  }
  .header .header-chip-avatar {
    display: flex;
  }
}