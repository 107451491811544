.event-details {
    display: grid;
    justify-items: center;
    gap: 1em;
    width: 100%;
}

.event-details ul {
    display: grid;
    gap: 0.5em;
    margin: 0;
}

.event-details label {
    font-weight: 600;
    padding-right: 1em;
}