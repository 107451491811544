

/* Standard Button */
.button {
    display: flex;
    border: none;
    border-radius: var(--small-radius);
    color: var(--font-color);
    background-color: rgba(0,0,0,0);
    padding: 0em 1.2em;
    justify-content: center;
    align-items: center;
    height: 2.5em;
    font-weight: 600;
    gap: 0.5em;
    flex-shrink: 0;
}

.button:enabled:hover {
    cursor: pointer;
    backdrop-filter: brightness(80%);
}
.button:disabled {
    color: var(--light-1) !important;
    cursor: default;
}

/* .button:not([variant|="icon"]) .MuiSvgIcon-root {
    margin-right: 6px;
} */

/* Icon */
.button[variant|="icon"] {
    padding: 0.2em;
    height: inherit;
}
.button[variant|="icon-outline"] {
    width: 3em;
    height: 3em;
    /* padding: 0.2em; */

    border-radius: 100px;
    border: 1px solid var(--dark-2);
    background-color: rgba(28, 28, 28, 0.5);
    
    /* backdrop-filter: blur(10px); */
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}
.button[variant|="icon-inline"] {
    width: 2em;
    height: 2em;
    margin-left: 0.5em;

    border-radius: 100px;
    border: 1px solid var(--dark-2);
    background-color: rgba(28, 28, 28, 0.5);
    
    /* backdrop-filter: blur(10px); */
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}
.button[variant|="icon-inline"] > svg {
    font-size: 1.25em !important;
}

/* Icon-Subtle */
.button[variant|="icon-subtle"] {
    padding: 0.2em;
    color: inherit;
    filter: brightness(50%);
}


/* Link */
.button[variant|="link"] {
    display: inline-flex;
    color: var(--hyperlink);
    padding: 0.2em;
    margin: 0 10px;
    font-weight: 400;
    background-color: rgba(0,0,0,0);
    text-decoration: underline;
    height: auto;
}

/* Link-Subtle */
.button[variant|="link-subtle"] {
    display: inline-flex;
    color: inherit;
    padding: 0.2em;
    margin: 0 10px;
    font-weight: 400;
    background-color: rgba(0,0,0,0);
    text-decoration: underline;
    height: auto;
}
.button[variant|="link-subtle"]:hover {
    color: var(--hyperlink);
    backdrop-filter: brightness(100%);
}

/* Primary */
.button[variant|="primary"] {
    background-color: var(--primary);
}
.button[variant|="primary"]:disabled {
    color: rgb(102, 102, 102);
    background-color: rgb(168, 168, 168);
    cursor: default;
}


/* Outlined */
.button[variant|="outline-light"] {
    border: 1px solid var(--light-2);
    background-color: inherit;
    /* color: var(--light-); */
}

.button[variant|="accent"] {
    background-color: aquamarine;
}

.button[variant|="warn"] {
    background-color: palevioletred;
}





/* Google Auth */
.button[variant|="auth"] {
    background-color: var(--light-5);
    color: var(--dark-3);
}

/* Google Auth */
/* .button[variant|="google-auth"] {
    background-color: var(--light-4);
    color: var(--dark-3);
} */
