.skeleton {
    background-image: linear-gradient(90deg, rgba(28, 28, 28, 0.5) 25%, rgba(40, 40, 40, 0.5) 50%, rgba(28, 28, 28, 0.5) 75%);
    background-size: 200% 100%;
    background-position: 100% 0%;
    animation: shimmer 1.5s infinite;
}
.skeleton.question {
    height: 500px;
}
.skeleton.answer {
    height: 71px;
}

.skeleton .main {
    display: flex;
    gap: 0.5em;
    padding: 0.5em;
}

.skeleton .input {
    width: 2em;
    height: 2em;
    border-radius: 100px;
    background-color: rgba(0,0,0,0);
    border: 1px solid var(--dark-2);
    align-self: center;
}

.skeleton .image {
    width: 48px;
    height: 48px;
    border-radius: var(--standard-radius);
    background-color: rgba(0,0,0,0);
    border: 1px solid var(--dark-2);
}

.skeleton .header-text {
    width: 100%;
    height: 3em;
    border-radius: var(--standard-radius);
    background-color: rgba(0,0,0,0);
    border: 1px solid var(--dark-2);
}

.skeleton .text {
    width: 140px;
    height: 1.5em;
    border-radius: var(--standard-radius);
    background-color: rgba(0,0,0,0);
    border: 1px solid var(--dark-2);
}

.skeleton .points {
    width: 45px;
    height: 27px;
    border-radius: var(--standard-radius);
    background-color: rgba(0,0,0,0);
    border: 1px solid var(--dark-2);
    justify-self: flex-end;
}
.fade-in {
    animation: fadeIn 1s forwards;
}

.fade-out {
    animation: fadeOut 1s forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.fade-element {
    opacity: 0;
}

@keyframes shimmer {
    to {
        background-position: -100% 0%;
    }
}