.stats-table {
  table-layout: fixed;
}
.stats-table td:first-child {
  padding-left: 0.5em;
}

.tiny-overlapping-logo {
  margin-bottom: -0.5em;
  margin-left: -0.5em;
  border-radius: 1em;
  max-height: 1.5em;
  max-width: 1.5em;
  background-color: black;
  padding: 2px;
}

.stats-table .subject-cell { 
  width: 100px; /* Adjust the width as needed */ 
  white-space: nowrap; 
}

.stats-table .stat-cell {
  vertical-align: bottom;
}